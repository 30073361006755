import { ThemeConfig } from 'antd';

import { COLORS } from './colors';

export const antTheme: ThemeConfig = {
  token: {
    fontFamily: `'Avenir Next Cyr', sans-serif`,
    fontWeightStrong: 600,
    colorLinkHover: COLORS.colorLinkHover,
    colorLinkActive: COLORS.colorLinkActive,
    colorIcon: COLORS.colorIcon,
    colorIconHover: COLORS.colorIconHover,
    colorLink: COLORS.colorLink,
    colorTextSecondary: COLORS.colorTextSecondary,
    colorTextQuaternary: COLORS.colorTextQuaternary,
    colorTextTertiary: COLORS.colorTextTertiary,
    colorText: COLORS.colorTextBase,
    colorTextBase: COLORS.colorTextBase,
    colorBgLayout: COLORS.colorBgLayout,
    colorBgSpotlight: COLORS.colorBgSpotlight,
    controlItemBgHover: COLORS.controlItemBgHover,
    controlItemBgActive: COLORS.controlItemBgActive,
    controlItemBgActiveHover: COLORS.controlItemBgActiveHover,
    colorPrimaryTextActive: COLORS.colorPrimaryTextActive,
    colorPrimaryText: COLORS.colorPrimaryText,
    colorPrimaryTextHover: COLORS.colorPrimaryTextHover,
    colorPrimaryActive: COLORS.colorPrimaryActive,
    colorPrimary: COLORS.colorPrimary,
    colorPrimaryHover: COLORS.colorPrimaryHover,
    colorPrimaryBorderHover: COLORS.colorPrimaryBorderHover,
    colorPrimaryBorder: COLORS.colorPrimaryBorder,
    colorPrimaryBgHover: COLORS.colorPrimaryBgHover,
    colorPrimaryBg: COLORS.colorPrimaryBg,
    colorBorder: COLORS.colorBorder,
  },
  components: {
    Button: {
      controlHeightLG: 54,
      colorBgContainer: COLORS.colorBgContainer,
      algorithm: true,
      defaultShadow: '',
      primaryShadow: '',
    },
    Checkbox: {
      colorBgContainer: COLORS.colorBgContainer,
    },
    Progress: {
      colorInfo: COLORS.colorPrimary,
    },
    Space: {
      paddingXS: 4,
    },
    Input: {
      controlHeight: 40,
      colorBgContainer: COLORS.colorBgContainer,
      algorithm: true,
      activeShadow: '',
      activeBorderColor: COLORS.control.activeBorderColor,
      hoverBorderColor: COLORS.control.activeBorderColor,
    },
    Form: {
      itemMarginBottom: 0,
    },
    Select: {
      colorBgContainer: COLORS.colorBgContainer,
      controlHeight: 40,
      algorithm: true,
      optionSelectedBg: COLORS.colorSelectActiveOption,
    },
    Avatar: {
      borderRadius: 8,
      colorTextPlaceholder: COLORS.colorNeutral,
    },
    Typography: {
      titleMarginBottom: 0,
      titleMarginTop: 0,
      // fontFamily: `'Argent CF', sans-serif`,
      colorTextDescription: COLORS.colorTextDescription,
    },
    Segmented: {
      itemSelectedBg: COLORS.white,
      // trackBg: COLORS.light300,
      controlHeight: 44,
    },
    Collapse: {
      headerBg: COLORS.white,
      headerPadding: 0,
      contentPadding: 0,
    },

    // https://ant.design/components/table#design-token
    Table: {
      borderColor: '#0601401a',
      cellFontSize: 14,
      cellPaddingBlock: 12,
      cellPaddingInline: 12,
      headerBg: '#06013608',
      headerBorderRadius: 12,
      headerColor: '#06013680',
    },
  },
};
